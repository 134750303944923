import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { OptionTypeBase } from 'react-select';
import { useIntercom } from 'react-use-intercom';
import { useLocalStorage } from 'usehooks-ts';
import ChooseDataModal, { TModalEntity } from '../../Components/ChooseDataModal/ChooseDataModal';
import { ITask } from '../../Components/TaskManager/TaskManager';
import ZoomCloudRecordingsListView from '../../Components/ZoomCloudRecordingsListView';
import languageOptions from '../../Consts/languageOptions';
import { defaultProjectIcons } from '../../Consts/projectIcons';
import { PropmptTemplate } from '../../Consts/promptTemplates';
import { ProjectChecklistContext } from '../../Context/ProjectChecklistContext';
import { UploadFilesContext } from '../../Context/UploadFilesContext';
import { AUTHORIZE_ZOOM } from '../../GraphQL/mutations';
import { FETCH_INTEGRATIONS } from '../../GraphQL/queries';
import useAnalytics from '../../Hooks/useAnalytics';
import useBoards, { useFetchLightBoards } from '../../Hooks/useBoards';
import useFeatureFlags, {
  featureNameGoogle,
  featureNameOneDrive,
} from '../../Hooks/useFeatureFlags';
import { useOrganization } from '../../Hooks/useOrganization';
import useUpload from '../../Hooks/useUpload';
import { Icon24 } from '../../Icons/Icon';
import { CloudFile } from '../../Models';
import { isDocx, isPdf, isTxt, isVideoOrAudio } from '../../Utils/fileTypes';
import { getZoomPublishableUrl } from '../../Utils/zoomUtils';
import {
  BottomBar,
  BottomBarRightSide,
  BottomButton,
  CloseButton,
  Container,
  CreditsInfo,
  CreditsLeft,
  Description,
  DescriptionCreditsText,
  DescriptionIcon,
  DescriptionText,
  GetMoreCredits,
  Header,
  HeaderLeftSide,
  MainContent,
  ProgressBarContainer,
  ProgressBarLine,
  ProjectIcon,
  ProjectSelect,
  ProjectSelectIcon,
  Title,
} from './styles';
import TasksStep from './TasksStep';
import UploadDataStep from './UploadDataStep';

export interface ITaskWithData extends ITask {
  prompt?: string;
  tagsEnabled?: boolean;
  speakerCount?: number;
  summaryTemplate?: PropmptTemplate;
  language?: OptionTypeBase;
}

const UploadData: FC = () => {
  const [currentStep, setCurrentStep] = useState<'uploading' | 'tasks' | 'zoom' | 'onedrive'>(
    'uploading'
  );
  const [currentFiles, setCurrentFiles] = useState<File[] | null>(null);
  const [currentCloudFiles, setCurrentCloudFiles] = useState<CloudFile[]>([]);
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const { analytics } = useAnalytics();
  const [currentDashboardId, setCurrentDashboardId] = useState<string | null>(null);
  const [currentRedirectUrl, setCurrentRedirectUrl] = useState<string>('');
  const [currentProject, setCurrentProject] = useState<TModalEntity | null>(null);
  const [boardsLoading, dashboards] = useFetchLightBoards();
  const [newProjectName, setNewProjectName] = useState<string>('');
  const [isChooseDataPopupOpen, setChooseDataPopupOpen] = useState(false);

  const [currentTemplate, setCurrentTemplate] = useState<PropmptTemplate | null>(null);
  const [currentLanguage, setCurrentLanguage] = useLocalStorage<OptionTypeBase>(
    'dataTranscriptionLanguage',
    languageOptions[0]
  );
  const [currentHighlightPrompt, setCurrentHighlightPrompt] = useState<string>('');
  const [tagsEnabled, setTagsEnabled] = useState(true);
  const [speakerCount, setSpeakerCount] = useState(10);
  const [currentTasks, setTasks] = useState<ITask[]>([]);
  const { markSummaryAdded } = useContext(ProjectChecklistContext);
  const [authorizeZoom] = useMutation(AUTHORIZE_ZOOM);
  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');
  const { data, loading, refetch: refetchIntegrations } = useQuery(FETCH_INTEGRATIONS);
  const { user } = useAuth0();
  const zoomConnected = data?.integrations?.find(
    (x: any) => x.type === 'zoom' && x.createdBy === user?.['https://notably.ai/claims/user_id']
  );
  const { isFeatureEnabled } = useFeatureFlags();
  const zoomEnabled = isFeatureEnabled('zoom');
  const googleDriveEnabled = isFeatureEnabled(featureNameGoogle);
  const oneDriveEnabled = isFeatureEnabled(featureNameOneDrive);
  const [isUploadingCloudFiles, seIsUploadingCloudFiles] = useState(false);
  const [, org] = useOrganization();
  const hasCredits = org.aiCredits > 0;
  const creditTotalAmount = org.aiCreditLimitTotal || org.aiCreditLimitMonthly;
  const currentScaleRatio = org.aiCredits / creditTotalAmount;
  const intercom = useIntercom();

  const {
    handleFileUpload,
    handleTranscriptUpload,
    handleCloudFileUpload,
    handleTxtUpload,
    handleDocxUpload,
    handlePdfUpload,
  } = useUpload();

  const {
    removeUploadingFile,
    removeAllUploadingFiles,
    setFilesUploadingModalOpen,
    setFileUploadingStatus,
    uploadFile,
    setFromOnboarding,
    uploadingFiles,
    fromOnboarding,
  } = useContext(UploadFilesContext);
  const { createBoard } = useBoards();

  const history = useHistory();

  useEffect(() => {
    if (fromOnboarding) {
      setCurrentFiles(uploadingFiles.map((file) => file.file));
      setCurrentStep('tasks');
      setFromOnboarding(false);
    }
  }, [uploadingFiles, fromOnboarding]);

  useEffect(() => {
    if (!currentFiles?.length && !currentCloudFiles.length && currentStep === 'tasks') {
      setCurrentStep('uploading');
    }
  }, [currentFiles, currentCloudFiles, currentStep]);

  useEffect(() => {
    if (!dashboardId) {
      setCurrentRedirectUrl(
        window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port ? ':' + window.location.port : '')
      );
      return;
    }

    if (dashboardId && !boardsLoading && dashboards) {
      const currentProject = dashboards.find((board) => board.id === dashboardId);
      if (!currentProject) return;

      setCurrentDashboardId(currentProject.id);
      setCurrentProject({
        id: currentProject.id,
        name: currentProject?.name,
        cover: currentProject?.cover,
      });
      setCurrentRedirectUrl(
        window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port ? ':' + window.location.port : '') +
          `/projects/${currentProject.id}/upload`
      );
    }
  }, [dashboardId, boardsLoading, dashboards]);

  useEffect(() => {
    if (code) {
      authorizeZoom({
        variables: {
          code,
          redirectUri: currentRedirectUrl,
        },
      })
        .then(refetchIntegrations)
        .finally(() => {
          history.replace(`/projects/${dashboardId}/upload`);
        });
    }
  }, [code, currentRedirectUrl]);

  const openZoomLogin = () => {
    window.location.href = getZoomPublishableUrl(currentRedirectUrl);
  };

  const handleZoomButtonClick = (isZoomConnected: boolean) => {
    if (isZoomConnected) {
      setCurrentStep('zoom');
    } else {
      setFilesUploadingModalOpen(false);
      openZoomLogin();
    }
  };

  const handleOneDriveButtonClick = () => {
    setCurrentStep('onedrive');
  };

  const handleCancelFile = (fileName?: string) => {
    if (!currentFiles?.length) return;

    if (fileName) {
      removeUploadingFile(fileName);
      setCurrentFiles((currentFiles) =>
        currentFiles ? currentFiles.filter((currentFile) => currentFile.name !== fileName) : []
      );
    } else {
      setCurrentFiles(null);
      removeAllUploadingFiles();
    }
  };

  const handleCancelCloudFile = (fileName?: string) => {
    if (!fileName) return;

    setCurrentCloudFiles((currentFiles) =>
      currentFiles.filter((currentFile) => currentFile.name !== fileName)
    );
  };

  const handleClose = () => {
    if (history.location.key) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  async function onUpload(file: File) {
    analytics.sendEvent('DataUpload_UploadedVideo', {
      ext: file.name.split('.').pop(),
    });
  }

  const getTasksWithData = useMemo(() => {
    if (!currentTasks.length) return [];

    return currentTasks.map((task) => {
      const newTask = { ...task } as ITaskWithData;
      if (task.id === 'highlight') {
        newTask.prompt = currentHighlightPrompt;
        newTask.tagsEnabled = tagsEnabled;
      }
      if (task.id === 'transcribe') {
        newTask.language = currentLanguage;
        newTask.speakerCount = speakerCount;
      }
      if (task.id === 'summarize' && currentTemplate) {
        newTask.summaryTemplate = currentTemplate;
      }
      return newTask;
    });
  }, [
    currentTasks,
    currentHighlightPrompt,
    currentLanguage,
    speakerCount,
    tagsEnabled,
    currentTemplate,
  ]);

  const saveTasksToLs = (fileId: string) => {
    if (!getTasksWithData.length) return;
    localStorage.setItem(`TASKS_${currentDashboardId}_${fileId}`, JSON.stringify(getTasksWithData));
  };

  const handleUpload = async () => {
    if (!currentFiles?.length && !currentCloudFiles?.length) return;

    let selectedDashboardId = '';

    if (!currentDashboardId) {
      const newProject = await createBoard(currentProject?.name || newProjectName);
      selectedDashboardId = newProject.id;
    } else {
      selectedDashboardId = currentDashboardId;
    }

    if (currentCloudFiles?.length) {
      seIsUploadingCloudFiles(true);
      for (const cloudFile of currentCloudFiles) {
        await handleCloudFileUpload(selectedDashboardId, cloudFile, {
          language: currentLanguage?.value,
          aiTemplate: currentTemplate,
          speakerCount: speakerCount,
          callback: saveTasksToLs,
        });
        analytics.sendEvent('DataUpload_UploadedCloudFile', {
          ext: cloudFile.name.split('.').pop(),
          mimeType: cloudFile.mimeType,
        });
      }
      seIsUploadingCloudFiles(false);
      history.push(`/projects/${selectedDashboardId}/data`);
    }

    if (currentFiles?.length) {
      for (const currentFile of currentFiles) {
        if (isVideoOrAudio(currentFile.name)) {
          analytics.sendEvent('DataUpload_StartingFileUpload', {
            ext: currentFile.name.split('.').pop(),
          });
          uploadFile(
            selectedDashboardId,
            currentFile,
            handleFileUpload,
            {
              language: currentLanguage?.value,
              speakerCount: speakerCount,
              aiTemplate: currentTemplate?.id,
              callback: saveTasksToLs,
            },
            (file) => {
              onUpload(file);
              handleCancelFile(file.name);
            }
          );
          continue;
        }

        handleCancelFile(currentFile.name);
        removeUploadingFile(currentFile.name);

        if (isDocx(currentFile.name)) {
          analytics.sendEvent('DataUpload_DocUpload', {});
          handleDocxUpload(selectedDashboardId, currentFile, {
            redirectAfterUpload: currentFiles.length === 1,
            template: currentTemplate,
            callback: saveTasksToLs,
          });
          continue;
        }

        if (isPdf(currentFile.name)) {
          analytics.sendEvent('DataUpload_PdfUpload', {});
          await handlePdfUpload(selectedDashboardId, currentFile, {
            redirectAfterUpload: currentFiles.length === 1,
            template: currentTemplate,
            callback: saveTasksToLs,
          });
          continue;
        }

        if (isTxt(currentFile.name)) {
          analytics.sendEvent('DataUpload_TxtUpload', {});
          handleTxtUpload(selectedDashboardId, currentFile, {
            redirectAfterUpload: currentFiles.length === 1,
            template: currentTemplate,
            callback: saveTasksToLs,
          });
          continue;
        }
        analytics.sendEvent('DataUpload_TranscriptUpload', {
          ext: currentFile.name.split('.').pop(),
        });
        handleTranscriptUpload(selectedDashboardId, currentFile, {
          redirectAfterUpload: currentFiles.length === 1,
          template: currentTemplate,
          callback: saveTasksToLs,
        });

        if (currentTemplate) {
          markSummaryAdded();
        }
      }
      history.push(`/projects/${selectedDashboardId}/data`);
    }
  };

  const handleChooseProject = (project: TModalEntity) => {
    setCurrentDashboardId(project.id);
    setCurrentProject(project);
    setNewProjectName('');
    setChooseDataPopupOpen(false);
  };

  const handleSaveToNewProject = (projectName: string) => {
    setCurrentProject(null);
    setNewProjectName(projectName);
    setChooseDataPopupOpen(false);
  };

  const getIconUrl = (cover?: string) => {
    const iconData = defaultProjectIcons.find((icon) => icon.name === cover);
    return iconData?.url || defaultProjectIcons[12].url;
  };

  const currentCreditsPrice = useMemo(() => {
    return currentTasks.reduce((acc, task) => acc + (task.creditsPrice || 0), 0);
  }, [currentTasks]);

  const hasVideo = useMemo(
    () =>
      currentFiles?.some((file) => isVideoOrAudio(file.name)) ||
      currentCloudFiles?.some((file) => isVideoOrAudio(file.name)),
    [currentFiles, currentCloudFiles]
  );

  return (
    <Container>
      <Header>
        <HeaderLeftSide>
          <Title>Add data / </Title>
          <ProjectSelect
            onClick={() => setChooseDataPopupOpen(!isChooseDataPopupOpen)}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <ProjectIcon src={getIconUrl(currentProject?.cover || '')} />
            {currentProject
              ? currentProject?.name || 'Untitled project'
              : newProjectName || 'Untitled project'}
            <ProjectSelectIcon>
              <Icon24.ChevronDown />
            </ProjectSelectIcon>
          </ProjectSelect>
        </HeaderLeftSide>
        <CloseButton onClick={handleClose}>
          <Icon24.Close />
        </CloseButton>
      </Header>
      <MainContent>
        {currentStep === 'uploading' && (
          <UploadDataStep
            onFinish={() => setCurrentStep('tasks')}
            onCurrentFilesChange={setCurrentFiles}
            onCurrentCloudFilesChange={setCurrentCloudFiles}
            onZoomButtonClick={() => (zoomEnabled ? handleZoomButtonClick(zoomConnected) : null)}
            onOneDriveButtonClick={() => (oneDriveEnabled ? handleOneDriveButtonClick() : null)}
            isUploadingCloudFiles={isUploadingCloudFiles}
            zoomEnabled={zoomEnabled}
            googleDriveEnabled={googleDriveEnabled}
            oneDriveEnabled={oneDriveEnabled}
          />
        )}

        {currentStep === 'tasks' && (
          <>
            <TasksStep
              currentFiles={currentFiles}
              currentCloudFiles={currentCloudFiles}
              currentLanguage={currentLanguage}
              currentTemplate={currentTemplate}
              currentHighlightPrompt={currentHighlightPrompt}
              currentTasks={currentTasks}
              tagsEnabled={tagsEnabled}
              onTagsEnabledChange={setTagsEnabled}
              onCurrentLanguageChange={setCurrentLanguage}
              onCurrentTemplateChange={setCurrentTemplate}
              onCurrentHighlightPromptChange={setCurrentHighlightPrompt}
              onCurrentTasksChange={setTasks}
              onCancelFile={handleCancelFile}
              onCancelCloudFile={handleCancelCloudFile}
              updateCurrentFiles={setCurrentFiles}
            />

            <BottomBar>
              <CreditsInfo>
                <CreditsLeft>
                  {hasCredits ? `${org.aiCredits} credits left` : 'You’re out of AI credits'}
                </CreditsLeft>
                <ProgressBarContainer>
                  <ProgressBarLine scaleRatio={currentScaleRatio} />
                </ProgressBarContainer>

                <GetMoreCredits
                  onClick={
                    hasCredits
                      ? () =>
                          intercom.showNewMessage(
                            'Hi, I want to get more AI credits. Please help me out.'
                          )
                      : () => history.push('/settings/plans')
                  }
                >
                  {hasCredits ? 'Get more credits' : 'Upgrade for more credits'}
                </GetMoreCredits>
              </CreditsInfo>

              <BottomBarRightSide>
                <Description>
                  <DescriptionIcon>
                    <Icon24.PostyGray />
                  </DescriptionIcon>
                  <DescriptionText>
                    {currentTasks.length + (hasVideo ? 1 : 0)} Posty Tasks
                  </DescriptionText>
                  <DescriptionCreditsText>
                    {currentCreditsPrice * (currentCloudFiles.length + (currentFiles?.length || 0))}{' '}
                    credits
                  </DescriptionCreditsText>
                </Description>

                <BottomButton
                  type="primary"
                  onClick={handleUpload}
                  disabled={(!currentDashboardId && !newProjectName) || isUploadingCloudFiles}
                >
                  Start Analysis
                </BottomButton>
                <BottomButton type="secondary" onClick={handleClose}>
                  Cancel
                </BottomButton>
              </BottomBarRightSide>
            </BottomBar>
          </>
        )}

        {currentStep === 'zoom' && (
          <ZoomCloudRecordingsListView
            onCancel={() => setCurrentStep('uploading')}
            onContinue={(files: CloudFile[], language) => {
              setCurrentLanguage(language);
              setCurrentCloudFiles(files);
              setCurrentStep('tasks');
            }}
          />
        )}
      </MainContent>

      {isChooseDataPopupOpen && (
        <ChooseDataModal
          type="insight"
          onClose={() => setChooseDataPopupOpen(false)}
          onChooseEntity={handleChooseProject}
          onCreateNewProject={handleSaveToNewProject}
          newProjectName={newProjectName}
          subtitile="Save files to a project for analysis"
          minimized
          position={{ left: 30, top: 48 }}
        />
      )}
    </Container>
  );
};

export default UploadData;
